import { combineReducers } from "redux";
// import storage from "redux-persist/lib/storage";
import dashboardReducer from "./dashboard/reducers/dashboard.reducers";
import generalReducer from "./general/reducers/general.reducers";
import hospitalReducer from "./hospital/reducers/hospital.reducers";
import productReducer from "./product/reducers/product.reducers";
import stateReducer from "./state/reducers/state.reducers";
import userReducer from "./users/reducers/users.reducers";
import currencyReducer from "./currency/reducers/currency.reducers";
import loginReducer from "./login/reducers/login.reducers";
import countryReducer from "./countries/reducers/countries.reducers";
import ovaReducer from "./oneVendor/reducers/onevendor.reducer";
import liteReducer from "./lite/reducers/lite.reducer";
import riderReducer from "./rider/reducers/rider.reducer";
import nerveReducer from "./nerve/reducers/nerve.reducer";
import monthReducer from "./month/reducers/month.reducers";
import yearReducer from "./year/reducers/year.reducers";
import hpReducer from "./oneHp/reducers/hp.reducer";
// import hpReducers from "./oneHp/reducers/hp.reducer";

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  hospital: hospitalReducer,
  product: productReducer,
  state: stateReducer,
  general: generalReducer,
  country: countryReducer,
  users: userReducer,
  currency: currencyReducer,
  login: loginReducer,
  ova: ovaReducer,
  lite: liteReducer,
  rider: riderReducer,
  nerve: nerveReducer,
  month:monthReducer,
  year:yearReducer,
  hp:hpReducer
});

export { rootReducer };
