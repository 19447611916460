// importing actions

import { FETCH_HOSPITAL_DATA } from "../../types";

// state
const INITIAL_STATE = {
  hospitalData: {},
};

// Redux reducer function
const hospitalReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case FETCH_HOSPITAL_DATA:
      return {
        ...state,
        hospitalData: payload,
      };

    default:
      return state;
  }
};

export default hospitalReducer;
