const config = {
  // baseURL: process.env.REACT_APP_LOCAL_API,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
};

// const token = localStorage.getItem("jwt-token");
// if (token) {
//   config.headers.authorization = `Bearer ${token}`;
// }

export default config;
