import React from "react";
import ReactLoading from "react-loading";

const Loader = () => {
  return (
    <div className='loader-container'>
      <ReactLoading type='spinningBubbles' color='#b70303' />
    </div>
  );
};

export default Loader;
