

import { CHANGE_COUNTRY } from "../../types";
const countries = [];
const initialState = {
  countries: countries,
};
const countryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_COUNTRY:
      return {
        ...state,
        countries: payload,
      };
    default:
      return state;
  }
};

export default countryReducer;
