const swapTitle = {
  "/report": "Report",
  "/report/hospital": "hospitals",
  "/report/product": "wallets",
  "/report/state": "states",
  "/home": "dashboard",
  "/report/order-breakdown": "order breakdown",
  "/settings": "settings",
  "/logout": "dashboard",
};

export default swapTitle;
