import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const Desc = ({ title, description, keywords }) => {
  const { pathname } = useLocation();
  const newPath = pathname.split("/")[1].toLocaleUpperCase();
  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${newPath} | LifeBank`}</title>
        <meta name={`${newPath}...`} content={description} />
      </Helmet>
    </HelmetProvider>
  );
};

// Desc.defaultProps = {
//   title: "Dashboard | LifeBank",
//   description: "Dashboard | LifeBank....",
// };

export default Desc;
