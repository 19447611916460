import { LOGIN_USER_CREDENTIALS } from "../../types";

//state
let user = JSON.parse(localStorage.getItem('user'))
const INITIAL_STATE = user ? { loggedIn: true, user} : { loggedIn: false };

//REducer
const loginReducer = (state = INITIAL_STATE, action) =>{
    const { type, payload } = action;

    switch(type){
        case LOGIN_USER_CREDENTIALS:
            return{
                ...state,
                loggedIn: true,
                user: payload
            };
        default:
            return state
    }
}

export default loginReducer;