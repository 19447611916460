import { NavLink } from "react-router-dom";
import style from "./Nav.module.scss";

const NavItem = (props) => {
  const { path, icon, title, setVisible } = props;
  return (
    <div className={style.item}>
      <NavLink
        to={path}
        className={({ isActive }) =>
          isActive ? style.item_active : style.item_link
        }
        onClick={() => setVisible(false)}
      >
        <div className={style.item_icon}>{icon}</div>
        <span className={style.item_label}>{title}</span>
      </NavLink>
    </div>
  );
};

export default NavItem;
