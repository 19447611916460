import NavBar from "../NavBar/NavBar.component";
import "./AppLayout.styles.scss";
import { Outlet, useLocation } from "react-router-dom";
// import { useLocalStorage } from "../../Hooks/useLocalStorage";
import Side from "../side/Side";
import { useEffect, useRef, useState } from "react";
import swapTitle from "../swapTitle";
// import { updateNavbarType } from "../../redux/slices/app";
import { useSelector } from "react-redux";
import { updateNavbarTypes } from "../../redux/general/actions/general.actions";

const AppLayout = () => {

  // const { getLocalStorage } = useLocalStorage("user");
  const [navTitle, setnavTitle] = useState("report");

  const { navbarType } = useSelector((state) => state.general);

  const path = useLocation();

  useEffect(() => {
    setnavTitle(path.pathname);
  }, [path.pathname]);

  const [toggle, setToggle] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const fullScreenRef = useRef(null);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setToggle(true);
      setIsFullScreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setToggle(false);
      setIsFullScreen(false);
    }
  };


  return (
    <div className='app-layout'>
      <div className='app-layout-side'>
        <Side active={toggle} />
      </div>

      <div
        className={
          toggle === false ? "app-layout-container" : "app-layout-active"
        }
      >
        <NavBar
          onClickYes={toggleFullScreen}
          fullScreenRef={fullScreenRef}
          hambugger={toggle || isFullScreen}
          title={swapTitle[navTitle]}
          type={navbarType}
          updateCountry={updateNavbarTypes}
        />
        <div className='app-layout-container-contents'>
          <Outlet />
        </div>
      </div>
    </div>
  );
  //  : (
  //   <Navigate to='login' replace />
  // );
};

export default AppLayout;