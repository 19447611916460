// importing actions

// importing actions

import { CHANGE_YEAR } from "../../types";

// state
const INITIAL_STATE = {
  yearType: 2024,
};

// Redux reducer function
const yearReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case CHANGE_YEAR:
      return {
        ...state,
        yearType: payload,
      };

    default:
      return state;
  }
};

export default yearReducer;
