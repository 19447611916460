import { somethingWentWrongToast } from "../../../components/NotificationToast";
import { CHANGE_COUNTRY } from "../../types";
import CountryServices from "../services/countries.service";

export const getCountry = (country, month) => async (dispatch) => {
  try {
    const response = await CountryServices.fetchCountry(country, month);
    dispatch({
      type: CHANGE_COUNTRY,
      payload: response.data,
    });
    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};
