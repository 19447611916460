import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

// layouts
import AppLayout from "../Layouts/AppLayout/AppLayout.layout";

// config
import { DEFAULT_PATH } from "../config";
import Loader from "../components/loader/Loader.component";


const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const isLoggedIn = JSON.parse(window.localStorage.getItem("user"))
  
  
  return useRoutes(
    isLoggedIn
    ? [
    {
      path: "/",
      element: <AppLayout />,
      children: [
        { element: <Navigate to={DEFAULT_PATH} replace />, index: true },
        { path: "home", element: <Home /> },
        {
          path: "report/hospital",
          element: <Hospital />,
        },
        {
          path: "report/product",
          element: <Product />,
        },
        {
          path: "report/state",
          element: <Page404 text="Coming soon!" />,
        },
        {
          path: "report/order-breakdown",
          element: <Page404 text="Coming soon!" />,
        },
        {
          path: "coreapps",
          element: <CoreApps />
        },
        {
          path: "coreapps/onehospital",
          element: <OneHospital />
        },
        {
          path: "coreapps/onevendor",
          element: <OneVendor />
        },
        {
          path: "coreapps/lite",
          element: <Lite />
        },
        {
          path: "coreapps/rider",
          element: <Rida />
        },
        {
          path: "coreapps/nerve",
          element: <Nerve />
        },
        {
          path: "settings/",
          element: <Settings />,
        },
        {
          path: "settings/users",
          element: <Users />,
        },
        {
          path: "settings/currency",
          element: <Currency />
        },
        {
          path: "settings/logout",
          element: <Logout />
        },
       

        // {
        //   path: "404",
        //   element: (
        //     <Page404
        //       text=" This page is under development.
        // Please go to home page"
        //     />
        //   ),
        // },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
   // { path: "*", element: <Navigate to="/404" replace /> },
  ]  : [{path: "/", element: <Login/>}],
  )
}

const Home = Loadable(lazy(() => import("../pages/home/Home.page")));
const Hospital = Loadable(
  lazy(() => import("../pages/Hospital/Hospital.page"))
);
const Product = Loadable(lazy(() => import("../pages/Product/Product.page")));
const State = Loadable(lazy(() => import("../pages/State/State.page")));
const Order = Loadable(lazy(() => import("../pages/order/Order.page")));
const CoreApps = Loadable(lazy(() => import("../pages/CoreApps/CoreApps.page")));
const OneHospital = Loadable(lazy(() => import("../pages/OneHospital/OneHospital.page")));
const OneVendor =Loadable(lazy(() => import("../pages/OneVendor/OneVendor.page")));
const Lite = Loadable(lazy(() => import("../pages/Lite/Lite.page")));
const Rida = Loadable(lazy(() => import("../pages/Rida/Rida.page")));
const Nerve =Loadable(lazy(() => import("../pages/Nerve/Nerve.page")));
const Settings = Loadable(
  lazy(() => import("../pages/Settings/Settings.pages"))
);
const Users = Loadable(lazy(() => import("../pages/Users/Users.page")));
const Currency = Loadable(lazy(() => import("../pages/Currency/Currency.page")));
const Login = Loadable(lazy(() => import("../pages/Login/Login.page")));
const Logout = Loadable(lazy(() => import("../pages/Logout/Logout.page")));
const Page404 = Loadable(lazy(() => import("../pages/Page404/Page404.page")));
