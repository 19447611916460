// importing actions
import { FETCH_DASHBOARD_NIGERIA } from "../../types";

const nigeriaData = [];

const initialState = {
  nigeriaData: nigeriaData,
};

const dashboardReducer = (state = initialState, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case FETCH_DASHBOARD_NIGERIA:
      return {
        ...state,
        nigeriaData: payload,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
