import { ENABLE_USER, FETCH_USER_DATA, POST_USER_DATA, PUT_USER_DATA } from "../../types";

// STATE
const INITIAL_STATE = {
  users: [],
}

// Reducer
const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USER_DATA:
      return {
        ...state,
        users: payload,
      };
    case POST_USER_DATA:
      return {
        ...state,
        users: [...state.users, payload],
      };
    case PUT_USER_DATA:
      return{
        ...state,
        users: state.users.map((user) => {
          if(user.id === payload.user.id){
            return {
              ...user,
              disabled: action.payload.disabled,
              //enabled: action.payload.user.enabled,
            };
          }
          return user;
        }),
      };
    case ENABLE_USER:
      return{
        ...state,
        users: state.users.map((user) => {
          if(user === payload.user.id){
            return{
              ...user,
              enabled: action.payload.enabled,
            };
          }
          return user;
        })
      }
    default:
      return state;
  }
};

export default userReducer;
