// importing actions

import { ONE_HP } from "../../types";

// state
const INITIAL_STATE = {
  productData: {},
};

// Redux reducer function
const hpReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case ONE_HP:
      return {
        ...state,
        productData: payload,
      };

    default:
      return state;
  }
};

export default hpReducer;
