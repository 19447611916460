import axiosClient from "../../../service/axios";
import { COUNTRY_URL } from "./countries.endpoint";

// fetch country
const fetchCountry = async () => {
    try {
      return await axiosClient.get(COUNTRY_URL);
    } catch (error) {
      return error;
    }
  };
  
  const CountryServices = {
    fetchCountry,
  };
  
  export default CountryServices;