import React from "react";
import style from "./Filter.module.scss";

const Filter = ({ filter, options, onChangeSelect }) => {
  return (
    <div className={style.container}>
      <select value={filter} onChange={onChangeSelect} className={style.btn}>
        {options.map((option) => (
          <option key={option.id} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Filter;
