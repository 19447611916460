// importing actions

import { RIDER_DATA } from "../../types";

// state
const INITIAL_STATE = {
  productData: {},
};

// Redux reducer function
const riderReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case RIDER_DATA:
      return {
        ...state,
        productData: payload,
      };

    default:
      return state;
  }
};

export default riderReducer;
