import React, { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useLocalStorage } from "../Hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState({});
  const { getLocalStorage, removeLocalStorage } = useLocalStorage("user");

  const navigate = useNavigate();

  useEffect(() => {
    let token = getLocalStorage;
    if (token) {
      let exp;
      try {
        let decoded = jwt_decode(token);
        exp = decoded.exp;
        setProfile(decoded);
      } catch (error) {
        exp = 0;
      }

      //expires in seconds on auth state
      // used to check if logged in
      // convert exp to millisec from sec
      // setExpiresIn(exp * 1000);
      localStorage.setItem("exp", exp * 1000);
      // console.log("expires in refresh", exp * 1000);
    } else {
      localStorage.setItem("exp", null);
    }
  }, [getLocalStorage]);

  const handleLogout = () => {
    removeLocalStorage();
    localStorage.removeItem("userType");
    navigate("/login");
  };

  return (
    <ProfileContext.Provider value={{ profile, handleLogout }}>
      {children}
    </ProfileContext.Provider>
  );
};
