/* 
@example

    export const ACTION_NAME = "ACTION_NAME"
*/

/*

@Dashboard

*/
export const FETCH_DASHBOARD_GLOBAL = "FETCH_DASHBOARD_GLOBAL";
export const FETCH_DASHBOARD_NIGERIA = "FETCH_DASHBOARD_NIGERIA";
export const FETCH_DASHBOARD_KENYA = "FETCH_DASHBOARD_KENYA";
export const FETCH_DASHBOARD_ETHIOPIA = "FETCH_DASHBOARD_ETHIOPIA";

export const CHANGE_NAV_TYPE = "CHANGE_NAV_TYPE";
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";

/*

@Hospital

*/
export const FETCH_HOSPITAL_DATA = "FETCH_HOSPITAL_DATA";

/*

@Product

*/
export const FETCH_PRODUCT_DATA = "FETCH_PRODUCT_DATA";

/*

@State

*/
export const FETCH_STATE_DATA = "FETCH_STATE_DATA";

/*

@Users

*/
export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const POST_USER_DATA = "POST_USER_DATA";
export const PUT_USER_DATA = "PUT_USER_DATA";
export const ENABLE_USER = "ENABLE_USER";

/*

@Currency

*/
export const FETCH_CURRENCY_DATA = "FETCH_CURRENCY_DATA";
export const POST_CURRENCY_DATA = "POST_CURRENCY_DATA";
export const PUT_CURRENCY_DATA = "PUT_CURRENCY_DATA";

/* 

@Login

*/
export const LOGIN_USER_CREDENTIALS = "LOGIN_USER_CREDENTIALS";

//
export const OVA_DATA = "OVA_DATA";
export const NERVE_DATA = "NERVE_DATA";
export const LITE_DATA = "LITE_DATA";
export const RIDER_DATA = "RIDER";
export const ONE_HP = "ONE_HP";



export const CHANGE_YEAR = "CHANGE_YEAR";
export const CHANGE_MONTH = "CHANGE_MONTH";
