// importing actions

import { FETCH_STATE_DATA } from "../../types";

// state
const INITIAL_STATE = {
  stateRecord: {},
};

// Redux reducer function
const stateReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case FETCH_STATE_DATA:
      return {
        ...state,
        stateRecord: payload,
      };

    default:
      return state;
  }
};

export default stateReducer;
