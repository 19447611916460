// importing actions

// importing actions

import { CHANGE_MONTH } from "../../types";
const currentMonth = new Date().getMonth() + 1;

// state
const INITIAL_STATE = {
  monthType: currentMonth,
};

// Redux reducer function
const monthReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case CHANGE_MONTH:
      return {
        ...state,
        monthType: payload,
      };

    default:
      return state;
  }
};

export default monthReducer;
