// import User from "../User/User.component";
import { useEffect } from "react";
import "./NavBar.styles.scss";
// import MobileHamburger from "./MobileHamburger/MobileHamburger.component";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";
import { getCountry } from "../../redux/countries/actions/countries.actions";

import Filter from "../../components/filter/Filter";
import { useDispatch, useSelector } from "react-redux";

const NavBar = ({
  onClickYes,
  hambugger,
  title,
  fullScreenRef,
  type,
  updateCountry,
}) => {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.country);
  
  useEffect(() => {
    dispatch(getCountry());
  }, [dispatch]);


  const handleSelection = (event) => {
    dispatch(updateCountry(event.target.value));
  };
  let countriess = countries?.map((item, index) => ({
    id: index,
    name: item,
  }));

  
  return (
    <div className="navbar">
      <div onClick={() => onClickYes()} className="navbar-icon"></div>
      <div className="navbar-title">
        <p>{title}</p>
      </div>
      <div className="navbar-column-one">{/* <MobileHamburger /> */}</div>
      <div className="navbar-column-two">
        <Filter
          placeholder={"Filter By"}
          filter={type}
          options={countriess}
          onChangeSelect={handleSelection}
        />{" "}
        <div className="navbar-column-two-docs">
          <div
            onClick={() => onClickYes()}
            className="navbar-icon"
            ref={fullScreenRef}
          >
            {hambugger === false ? (
              <BiFullscreen size={30} onClick={() => onClickYes()} />
            ) : (
              <BiExitFullscreen size={30} />
            )}
          </div>
        </div>
        {/* <BsThreeDots size={30} className='user-dropdown-container-caret' /> */}
      </div>
    </div>
  );
};

export default NavBar;
