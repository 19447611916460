// importing actions

// importing actions

import { CHANGE_NAV_TYPE } from "../../types";

// state
const INITIAL_STATE = {
  navbarType: "nigeria",
};

// Redux reducer function
const generalReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case CHANGE_NAV_TYPE:
      return {
        ...state,
        navbarType: payload,
      };

    default:
      return state;
  }
};

export default generalReducer;
