import "./App.scss";
import { UtilProvider } from "./Context/UtilContext";
import { ProfileProvider } from "./Context/ProfileContext";
import Desc from "./components/Desc";
import { toast, ToastBar, Toaster } from "react-hot-toast";
import Router from "./routes";

function App() {

  return (
    <div className='App'>
      <Desc />
      <Toaster
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== "loading" && (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => toast.dismiss(t.id)}
                  ></div>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>  
          
      <UtilProvider>
        {" "}
        <ProfileProvider>
          <Router />      
        </ProfileProvider>
      </UtilProvider>
    </div>
  );

}

export default App;
