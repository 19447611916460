import toast from "react-hot-toast";
import Swal from "sweetalert2";

// const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 2000));
// @General
export const somethingWentWrongToast = () =>
  toast.error("Something Went Wrong");

export const divertedOrderToast = () =>
  toast.error("You cannot edit this order!");

export const lowQuantityToast = () =>
  toast.error("Product quantity not available");

export const subOrdererrorToast = () =>
  toast.error("The order has not yet been delivered");

export const batchTakenToast = () =>
  toast.error("Batch number is already taken");

export const packetAssignedToast = () =>
  toast.error("This pack has already been assigned to an order!");

// @Authentication
export const loginSuccessToast = () => toast.success("Login Successfully");
export const logoutSuccessToast = () => toast.success("");
export const invalidCredentialToast = () => toast.error("Invalid Credentials");
export const passwordChangedSuccessToast = () =>
  toast.success(
    `Password Updated Successfully.
    Sign in with your new password`
  );

export const passwordChangedWarningToast = () =>
  toast.error(`Invalid old password`);

// @Order
export const orderCreateSuccessToast = () =>
  toast.success("Order Created Successfully");

// @faild order
export const failedOrder = () =>
  toast.error("Order not successfully placed. Try again");

export const noNetworkToast = () => toast.error("Connection Error! ");
export const accessDeniedToast = () => toast.error("Access Denied!");

export const offlineToast = () =>
  toast.error("You are currently offline!! \n Please check your internet", {
    position: "bottom-left",
  });
export const onlineToast = () =>
  toast.success("Connected!", {
    position: "bottom-left",
  });

export const sweetAlert = (test) => {
  Swal.fire({
    title: "Success",
    text: test,
    icon: "success",
    confirmButtonText: "OKAY",
    confirmButtonColor: "#b70303",
  }).then(function () {
    window.location.reload();
  });
};
