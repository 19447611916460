import axios from "axios";
import config from "./config";

const axiosClient = axios.create(config);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosClient;
