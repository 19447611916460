import React, { useState } from "react";
import { TbArrowsDoubleNeSw } from "react-icons/tb";
import { FiSettings, FiLogOut } from "react-icons/fi";
import style from "./side.module.scss";
import logo from "../../assets/images/icon.png";
import NavSubrouteItem from "./navSubrouteItem";
import NavItem from "./navItem";
import myRoutes from "../../utils/routes";

const Side = ({ active }) => {

  const links = [
    myRoutes.dashboard.home,
    {
      icon: <TbArrowsDoubleNeSw />,
      title: "Report",
      subRoutes: [
        myRoutes.dashboard.hospital,
        myRoutes.dashboard.product,
        myRoutes.dashboard.state,
        myRoutes.dashboard.breakdown,
      ],
    },
    myRoutes.dashboard.coreapps,
    {
      icon: <FiSettings />,
      title: "Setting",
      subRoutes: [
        myRoutes.dashboard.users,
        myRoutes.dashboard.currency,
        myRoutes.dashboard.logout,
      ],
    },
  
    // {
    //   title: "CoreApps",
    //   // icon: <FiLogOut />,
    //   // subRoutes: [
    //   //   myRoutes.dashboard.logout
    //   // ]
    // }
  ];


  return (
    <div className={active ? style.inactive : style.main}>
      <div className={style.main_logoContainer}>
        <img src={logo} alt="lifebank" width={40} height={40} />
        <div className={style.main_logoContainer_logo}>Lifebank</div>
      </div>

      <div className={style.main_items}>
        <div className={style.main_items_inner}>
          {links?.map((route) => {
            return route.subRoutes ? (
              <NavSubrouteItem key={route.title} {...route} />
            ) : (
              <NavItem
                key={route.title}
                {...route}
              />
            );
          })}
        </div>
      </div> 
      <div>
        
      </div>
    </div>
  );
};

export default Side;
