import { FETCH_CURRENCY_DATA, POST_CURRENCY_DATA, PUT_CURRENCY_DATA } from "../../types";

//STATE
const INITIAL_STATE = {
    currency: [],
}

//Reducer
const currencyReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_CURRENCY_DATA:
            return {
                ...state,
                currency: payload,
            };
        case POST_CURRENCY_DATA:
            return{
                ...state,
                currency: [...state.currency, payload],
            };
        case PUT_CURRENCY_DATA:
            return{
                ...state,
                currency: payload,
            };
        default:
            return state;
    }
};

export default currencyReducer;