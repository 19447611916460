import { AiOutlineHome } from "react-icons/ai";
import { FaLocationArrow, FaProductHunt } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi"
import { MdLocalHospital } from "react-icons/md";
import { TbArrowsDoubleNeSw } from "react-icons/tb";
import { AiFillAppstore } from "react-icons/ai";

const myRoutes = {
  dashboard: {
    home: { icon: <AiOutlineHome />, title: "Home", path: "/home" },
    report: { icon: <TbArrowsDoubleNeSw />, title: "Report", path: "/report" },
    coreapps: { icon: <AiFillAppstore />, title: "CoreApps", path: "/coreapps"},
    onehospital: { title: "OneHospital", path: "/coreapps/onehospital", },
    onevendor: { title: "OneVendor", path: "/coreapps/onevendor", },
    lite: { title: "Lite", path: "/coreapps/lite", },
    rider: { title: "Rider", path: "/coreapps/rider", },
    nerve: { title: "Nerve", path: "/coreapps/nerve", },
    setting: { icon: <FiSettings />, title: "Settings", path: "/settings" },
    users: {
      title: "Users",
      path: "/settings/users",
    },
    currency: {
      title: "Currency",
      path: "/settings/currency",
    },
    logout: { 
      title: "Logout", 
      path: "/settings/logout",
    },

    hospital: {
      icon: <MdLocalHospital />,
      title: "Hospital",
      path: "/report/hospital",
    },
    product: {
      icon: <FaProductHunt />,
      title: "Wallet",
      path: "/report/product",
    },
    breakdown: {
      icon: <FaProductHunt />,
      title: "Order",
      path: "/report/order-breakdown",
    },
    state: { icon: <FaLocationArrow />, title: "State", path: "/report/state" },

    // logout: { icon: <FiLogOut />, title: "Logout", path: "/logout"},


  },

};

export default Object.freeze(myRoutes);
