import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import style from "./Nav.module.scss";

const NavSubrouteItem = ({ icon, title, subRoutes }) => {
  const [visible, setVisible] = useState(false);

  const handleItemClick = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  return (
    <div className={style.item}>
      <div className={style.item_link} onClick={handleItemClick}>
        <div>{icon}</div>
        <span className="label">{title}</span>
        {visible ? <BsFillCaretUpFill /> : <BsFillCaretDownFill />}
      </div>

      {visible && (
        <ul className={style.item_list}>
          {subRoutes.map((subRoute) => (
            <li key={subRoute.title}>
              <NavLink
                to={subRoute.path}
                className={({ isActive }) =>
                  isActive ? style.item_active : style.item_link
                }
              >
                {subRoute.title}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavSubrouteItem;
