import * as React from "react";
import { useClickOutside } from "../Hooks/useClickOutside";
import { useMediaQuery } from "../Hooks/useMediaQuery";

export const UtilContext = React.createContext();

const initData = {
  hospital: "",
  username: "",
  email: "",
  date: "",
  phone: "",
  status: "",
};

export const UtilProvider = ({ children }) => {
  const [filterData, setFilterData] = React.useState(initData);
  const [showHamburger, setShowHamburger] = React.useState(false);
  const { ref, ref1, visible, setVisible } = useClickOutside();
  const isMobile = useMediaQuery();

  // toggle hamburger on smaller screens
  const toggleHamburger = () => {
    setShowHamburger((prevS) => !prevS);

    setTimeout(() => {
      if (!showHamburger && isMobile) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }, 100);
  };

  const showFilter = () => {
    setVisible((prevS) => !prevS);
  };

  const closeFilter = () => {
    setVisible(false);
  };

  const resetFilterData = () => {
    setFilterData(initData);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterData((prevS) => ({ ...prevS, [name]: value }));
  };

  const selectChange = (name, value) => {
    setFilterData((prevS) => ({ ...prevS, [name]: value }));
  };

  return (
    <UtilContext.Provider
      value={{
        toggleHamburger,
        showHamburger,
        ref,
        ref1,
        filterData,
        visible,
        showFilter,
        closeFilter,
        resetFilterData,
        handleChange,
        selectChange,
        isMobile,
      }}
    >
      {children}
    </UtilContext.Provider>
  );
};
